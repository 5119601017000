<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="Debit Note Claim Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operating Unit/Teritory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedOUs"
                  :options="optionOUs"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  track-by="id"
                  :custom-label="customLabel"
                  @search-change="findOUs"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Supplier</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedSuppliers"
                  :options="optionSuppliers"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  track-by="id"
                  :custom-label="customLabel"
                  @search-change="findSuppliers"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedItems"
                  :options="optionItems"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  track-by="id"
                  :custom-label="customLabel"
                  @search-change="findItems"
                  :searchable="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :close-on-select="false"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>DN Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="dNDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearDepositDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="postingDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearCollectionDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Open Key Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="true"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="openKeyDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }}
                  </template>
                </datepicker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>SOA Number</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <input
                  type="number"
                  v-model="soaNumber"
                  class="w-full p-3 rounded border border-grey"
                  placeholder="Enter SOA Number"
                />
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :territoryIDs="this.territoryIDs"
                  :territoryNames="this.territoryNames"
                  :draw="this.draw"
                  :ouIDs="this.ouIDs"
                  :ouNames="this.ouNames"
                  :startDNDate="this.dNDate.startDate"
                  :endDNDate="this.dNDate.endDate"
                  :startPostingDate="this.postingDate.startDate"
                  :endPostingDate="this.postingDate.endDate"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
import { debounce } from 'lodash';

export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/report/debit-note',
      selectedTerritories: [],
      optionTerritories: [],
      selectedOUs: [],
      optionOUs: [],
      selectedSuppliers: [],
      optionSuppliers: [],
      selectedItems: [],
      optionItems: [],
      territoryIDs: [],
      territoryCodes: [],
      territoryNames: [],
      draw: 0,
      dNDate: {
        startDate: null,
        endDate: null,
      },
      postingDate: {
        startDate: null,
        endDate: null,
      },
      dueDate: {
        startDate: null,
        endDate: null,
      },
      isLoading: false,
      ouQuery: '',
      supplierQuery: '',
      itemQuery: '',
      ouIDs: [],
      ouNames: [],
      openKeyDate: null,
      soaNumber: null,
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.code === '-' ? `${val.name}` : `(${val.code}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },
    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getTerritories() {
      this.$http.get('/api/report/v1/master/territory').then((resp) => {
        this.optionTerritories = resp.data.records;
      });
    },
    getOUCodes() {
      this.isLoading = true;
      const queryString = '?search=' + this.ouQuery;
      this.$http
        .get(
          '/api/report/v1/master/ou' +
            (this.ouQuery && this.ouQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionOUs = resp.data.records;
          this.isLoading = false;
        });
    },
    getSuppliers() {
      this.isLoading = true;
      const queryString = '?search=' + this.supplierQuery;
      this.$http
        .get(
          '/api/report/v1/master/supplier' +
            (this.supplierQuery && this.supplierQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionSuppliers = resp.data.records;
          this.isLoading = false;
        });
    },
    getItems() {
      this.isLoading = true;
      const queryString = '?search=' + this.itemQuery;
      this.$http
        .get(
          '/api/report/v1/master/item' +
            (this.itemQuery && this.itemQuery !== '' ? queryString : '')
        )
        .then((resp) => {
          this.optionItems = resp.data.records;
          this.isLoading = false;
        });
    },
    findOUs: debounce(function (query) {
      this.ouQuery = query;
    }, 500),
    findSuppliers: debounce(function (query) {
      this.supplierQuery = query;
    }, 500),
    findItems: debounce(function (query) {
      this.itemQuery = query;
    }, 500),
    handleDrawTable() {
      this.draw++;
    },
    clearDepositDate() {
      this.dNDate.startDate = null;
      this.dNDate.endDate = null;
    },
    clearCollectionDate() {
      this.postingDate.startDate = null;
      this.postingDate.endDate = null;
    },
    clearDueDate() {
      this.dueDate.startDate = null;
      this.dueDate.endDate = null;
    },
  },
  mounted() {
    this.getTerritories();
    this.getOUCodes();
    this.getSuppliers();
    this.getItems();
  },
  watch: {
    selectedTerritories(val) {
      let territoryIDs = [];
      let territoryCodes = [];
      let territoryNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          territoryIDs.push(e.id);
          territoryCodes.push(e.code);
          territoryNames.push(e.name);
        }
      });

      this.territoryIDs = territoryIDs;
      this.territoryCodes = territoryCodes;
      this.territoryNames = territoryNames;
    },
    selectedVendors(val) {
      let ouIDs = [];
      let ouCodes = [];
      let ouNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          ouIDs.push(e.id);
          ouCodes.push(e.Code);
          ouNames.push(e.Name);
        }
      });
      this.ouIDs = ouIDs;
      this.ouNames = ouNames;
    },
    ouQuery(val) {
      this.getOUCodes();
    },
    supplierQuery(val) {
      this.getSuppliers();
    },
    itemQuery(val) {
      this.getItems();
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
